import React, { useState, createContext } from "react"
export const ErrorContext = createContext()

export const ErrorProvider = (props) => {
  const [errorCodes, setErrorCodes] = useState({
    tokenExp: '0004',
    /* Request Exception */
    '10001': 'Hamis token.',
    '10002': 'A token lejárt!',
    '10003': 'Az oldal amit keres nem található.',
    '10004': 'Váratlan hiba.',
    '10005': 'A metódus nem engedélyezett.',
    '10006': 'Hiányzó adat. (Argument)',
    '10007': 'Hozzáférés tiltva!',
    '10008': 'Az Origin headert be kell állítani!',
    /* User Exception */
    '11001': 'Töltse ki az email mezőt!',
    '11002': 'Töltse ki a jelszó mezőt!',
    '11003': 'Töltse ki a felhasználónév mezőt!',
    '11004': 'Töltse ki a telefonszám mezőt!',
    '11005': 'Töltse ki az adószám mezőt!',
    '11006': 'Fogadja el a felhasználási feltételeket!',
    '11007': 'Fogadja el az adatkezelési feltételeket!',
    '11008': 'Érvénytelen e-mail cím.',
    '11009': 'Érvénytelen jelszó cím.',
    '11010': 'Érvénytelen felhasználónév cím.',
    '11011': 'Érvénytelen telefonszám.',
    '11012': 'Érvénytelen adószám.',
    '11013': 'Érvénytelen számlázási név.',
    '11014': 'Érvénytelen számlázási adat.',
    '11015': 'Fogadja el a 2mbeauty felhasználási feltételeit!',
    '11016': 'Fogadja el a 2mbeauty adatkezelési feltételeit!',
    '11017': 'Az e-mail cím használtban van.',
    '11018': 'A felhasználó nem ismert.',
    '11019': 'A jelszó és az újra jelszó nem egyezik meg.',
    '11020': 'Adja meg az újra jelszó mezőt.',
    '11021': 'Nem létező jelszó igénylő kód.',
    '11022': 'A jelszó token lejárt.',
    '11023': 'Az e-mail nincs regisztrálva a rendszerünkben.',
    '11024': 'Nem sikerült a jelszó igénylő e-mail kiküldése.',
    '11025': 'Hibás jelszó!',
    /* Address Exception */
    '12001': 'Hiányzó címzett mező!',
    '12002': 'Hiányzó ország mező!',
    '12003': 'Hiányzó irányitószám mező!',
    '12004': 'Hiányzó város mező!',
    '12005': 'Hianyzó cím mező!',
    '12006': 'Hibás cím mező!',
    '12007': 'Hibás város mező!',
    '12008': 'Hibás címzett mező!',
    '12009': 'Nem található ország!',
    '12010': 'Nem található szállítási cím!',
    '12011': 'Hibás szállítási adatok!',
    /* DB Exception */
    '13001': 'Hiba a létrehozás során!',
    '13002': 'Hiba a olvasás során!',
    '13003': 'Hiba a törlés során!',
    '13004': 'Hiba a frissítés során!',
    /* Variant Exception */
    '14001': 'Kiszerelés nem található!',
    '14002': 'A kiszerlés már kifutott.',
    '14003': 'Hiányzó kiszerelés id! (variant id)',
    /* Basket Exception */
    '15001': 'A kosár nem található!',
    '15002': 'A kosárban a termék értéke kizárólag pozitiv lehet!',
    '15003': 'Hiányzó kiszerelés!',
    '15004': 'Hiányzó kupon!',
    /* Order Exception */
    '16001': 'Hianyzó fizetési mód!',
    '16002': 'Hianyzó szállítási mód!',
    '16003': 'Hianyzó szállítási cím!',
    '16004': 'Fizetési mód nem létezik!',
    '16005': 'Szállítási mód nem létezik!',
    '16006': '',
    '16007': 'Rendelés nem létezik!',
    '16008': 'A kosár nem lehet üres!',
    /* Coupon Exception */
    '17001': 'A kupon nem elérhető!',
    '17002': 'A kupon lejárt!',
    '17003': 'A kupon fel lett használva!',
  });

  return (
    <ErrorContext.Provider value={[errorCodes, setErrorCodes]}>
      {props.children}
    </ErrorContext.Provider>
  );
};
