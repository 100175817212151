import React, { useState, createContext } from 'react';
export const RoutingContext = createContext();

export const RoutingProvider = props => {
  const [routing, setRouting] = useState({
    history: [],
    lastUrl: undefined
  });

  return (
    <RoutingContext.Provider value={[routing, setRouting]} >
      {props.children}
    </RoutingContext.Provider>
  );
}
