import React, { Suspense } from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie';
/* Providers */
import { AppProvider } from './contexts/AppContext'
import { ShopProvider } from './contexts/ShopContext'
import { DisplayProvider } from './contexts/DisplayContext'
import { FormProvider } from './contexts/FormContext'
import { RoutingProvider } from './contexts/RoutingContext'
import { ApiProvider } from './contexts/ApiContext'
import { ErrorProvider } from './contexts/ErrorContext'
import { ConfigProvider } from './contexts/ConfigContext'
import { CheckoutProvider } from './contexts/CheckoutContext'
/* style */
import './App.scss';
/* pages */
//const Home = React.lazy(() => import ('./pages/Home/Home'));
//const Home = React.lazy(() => import ('./pages/Home2/Home2'));
const Home = React.lazy(() => import ('./pages/Home3/Home3'));
const Premiere = React.lazy(() => import ('./pages/Premiere/Premiere'));
const Instructor = React.lazy(() => import ('./pages/Instructor/Instructor'));
/* const Intrested = React.lazy(() => import ('./pages/Intrested/Intrested')); */
const Shop = React.lazy(() => import('./pages/Shop/Shop'))
const Product = React.lazy(() => import ('./pages/Product/Product'))
const NotFound = React.lazy(() => import ('./pages/NotFound/NotFound'));
const ShopController = React.lazy(() => import ('./components/ShopController/ShopController'))
const SiteRedirect = React.lazy(() => import ('./components/SiteRedirect/SiteRedirect') )
const Checkout = React.lazy(() => import('./pages/Checkout/Checkout'))
const CheckoutFinish = React.lazy(() => import('./pages/CheckoutFinish/CheckoutFinish'))
const CheckoutSuccess = React.lazy(() => import('./pages/CheckoutSuccess/CheckoutSuccess'))
const Cart = React.lazy(() => import('./pages/Cart/Cart') )
const ProfileSettings = React.lazy(() => import('./pages/ProfileSettings/ProfileSettings'))
const Order = React.lazy(() => import('./pages/Order/Order'))
/* const TestPage = React.lazy(() => import('./pages/TestPage/TestPage')) */
const Adatvedelem = React.lazy(() => import('./pages/Adatvedelem/Adatvedelem'))
const BiztonsagiAdatlapok = React.lazy(() => import('./pages/BiztonsagiAdatlapok/BiztonsagiAdatlapok'))
const Aszf = React.lazy(() => import('./pages/Aszf/Aszf'))
const Impresszum = React.lazy(() => import('./pages/Impresszum/Impresszum'))
const RendelesMenete = React.lazy(() => import('./pages/RendelesMenete/RendelesMenete'))
/* const CoursesPage = React.lazy(() => import('./pages/CoursesPage/CoursesPage')) */
const CoursesComing = React.lazy(() =>import('./pages/CoursesComing/CoursesComing'))
const Wishlist = React.lazy(() => import('./pages/Wishlist/Wishlist'))
const PasswordReset = React.lazy(() => import('./pages/PasswordReset/PasswordReset'))

function App() {
  return (
    <DisplayProvider>
    <ConfigProvider>
    <ShopProvider>
    <ErrorProvider>
    <CookiesProvider>
    <ApiProvider>
    <AppProvider>
    <CheckoutProvider>

            <FormProvider>
              <RoutingProvider>
                <div className="App">
                  <Router>
                    <Suspense fallback={
                      <div className="loading-container">
                        <p className="loading-text">Betöltés...</p>
                      </div>
                    }>
                      <Switch>
                        {/* Website links */}
                        <Route path={"/"} exact component={Home} />
                        <Route path={"/bemutato"} component={Premiere} />
                        <Route path={"/oktato"} component={Instructor} />
                        <Route path={"/jelentkezes"} component={CoursesComing} />
                        {/* <Route path={"/jelentkezes"} component={CoursesPage} /> */}
                        {/* <Route path={"/jelentkezes"} component={Intrested} /> */}

                        {/* Webshop links */}
                        <Route path={"/shop/:id"} component={Shop} />
                        <Route path={"/termek/:id"} component={Product} />
                        <Route path={"/controller/:id"} component={ShopController} />
                        <Route path={"/penztar"} component={Checkout} />
                        <Route path={"/penztar-veglegesites"} component={CheckoutFinish} />
                        <Route path={"/sikeres-rendeles"} component={CheckoutSuccess} />
                        <Route path={"/kosar"} component={Cart} />
                        <Route path={"/kivansaglista"} component={Wishlist} />
                        <Route path={"/adatlapom"} component={ProfileSettings} />
                        <Route path={"/rendeles/:id"} component={Order} />
                        <Route path={"/redirect/:id"} component={SiteRedirect} />
                        <Route path={"/adatvedelem"} component={Adatvedelem} />
                        <Route path={"/aszf"} component={Aszf} />
                        <Route path={"/impresszum"} component={Impresszum} />
                        <Route path={"/rendeles-menete"} component={RendelesMenete} />
                        <Route path={"/biztonsagi-adatlapok"} component={BiztonsagiAdatlapok} />
                        
                        <Route path={"/elfelejtett-jelszo/:id"} component={PasswordReset} />
                        <Route path={"/elfelejtett-jelszo"} component={PasswordReset} />
                        
                        {/* <Route path={"/elfelejtett-jelszo/:id"} component={PasswordNew} /> */}
                        {/* <Route path={"/oktatas-vasarlas"} component={CoursesPage} /> */}
                        {/* <Redirect path={"/dipandgotwo/build/"} to="/" /> */}
                        <Redirect path={"/termek"} to="/shop" />
                        <Redirect path={"/shop"} to={"/shop/791"}/>

                        {/* Test links */}
                        {/* <Route path={"/test"} component={TestPage} /> */}


                        {/* Not found link */}
                        <Route component={NotFound}/>
                      </Switch>
                    </Suspense>
                  </Router>
                </div>
              </RoutingProvider>
            </FormProvider>
    
    </CheckoutProvider>
    </AppProvider>
    </ApiProvider>
    </CookiesProvider>
    </ErrorProvider>
    </ShopProvider>
    </ConfigProvider>
    </DisplayProvider>
  );
}

export default App;
