import React, { useState, createContext } from 'react';
export const AppContext = createContext();

export const AppProvider = props => {
  const [app, setApp] = useState({
    cookie: false,
    langs: ['HU', 'EN', 'RS'],
    lang: 'HU',
    registration: false,
    check: false,
    name: undefined,
    email: undefined,
    phone: undefined,
    city: undefined,
    otherCity: '',
    otherCountry: '',
    radio1: true,
    radio2: false,
    radio3: false,
    privacy: false,
    message: false
  });

  return (
    <AppContext.Provider value={[app, setApp]} >
      {props.children}
    </AppContext.Provider>
  );
}
