import React, { useState, createContext } from "react";
export const ApiContext = createContext();

export const ApiProvider = (props) => {
  const [api, setApi] = useState({
  //LIVE
  init: "https://2mbeauty.com/api/dipandgo/init",
  login: "https://2mbeauty.com/api/login",
  user: "https://2mbeauty.com/api/user",
  sendPwdEmail: "https://2mbeauty.com/api/user/password",
  checkPwdToken: "https://2mbeauty.com/api/user/password/",
  sendForgottenPwd: "https://2mbeauty.com/api/user/password/",
  products: "https://2mbeauty.com/api/product/list?category_in=",
  //product: "https://2mbeauty.com/api/product/", // description with url-s
  product: "https://2mbeauty.com/api/dipandgo/product/",
  addressesList: "https://2mbeauty.com/api/address/list",
  addresses: "https://2mbeauty.com/api/address",
  wishList: "https://2mbeauty.com/api/wishlist",
  cart: "https://2mbeauty.com/api/basket",
  cityList: "http://192.168.1.118:8000/2mapi/city/list?name=", //local on my pc
  countryList: "https://2mbeauty.com/api/country/", //local on my pc
  ordersList: "https://2mbeauty.com/api/order/list", // local on my pc
  orderById: "https://2mbeauty.com/api/order/", // local on my pc
  order: "https://2mbeauty.com/api/order/",
  successText : "https://2mbeauty.com/api/dipandgo/page/11"

/*
  //LOCAL
  login: "http://192.168.1.113/2mbeauty.com/api/login",
  user: "http://192.168.1.113/2mbeauty.com/api/user",
  products: "https://2mbeauty.com/api/product/list?category_in=",
  product: "https://2mbeauty.com/api/product/",
  addressesList: "http://192.168.1.113/2mbeauty.com/api/address/list",
  addresses: "http://192.168.1.113/2mbeauty.com/api/address",
  wishList: "http://192.168.1.113/2mbeauty.com/api/wishlist",
  cart: "http://192.168.1.113/2mbeauty.com/api/basket",
  cityList: "http://192.168.1.118:8000/2mapi/city/list?name=",
  countryList: "http://192.168.1.118:8000/2mapi/country/list?name=",
  ordersList: "http://192.168.1.113/2mbeauty.com/api/order/list",
  orderById: "http://192.168.1.113/2mbeauty.com/api/order/",
  order: "http://192.168.1.113/2mbeauty.com/api/order/",
  successText : "http://192.168.1.113/2mbeauty.com/api/dipandgo/page/11"
 */
  });

  return (
    <ApiContext.Provider value={[api, setApi]}>
      {props.children}
    </ApiContext.Provider>
  );
};
