import React, { useState, createContext } from "react";
export const ConfigContext = createContext();

export const ConfigProvider = (props) => {
  const [config, setConfig] = useState({
    animation: {
      premiere: true
    }
  });

  return (
    <ConfigContext.Provider value={[config, setConfig]}>
      {props.children}
    </ConfigContext.Provider>
  );
};
