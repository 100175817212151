import React, { useState, createContext } from 'react';
export const CheckoutContext = createContext();

export const CheckoutProvider = props => {
  const [checkout, setCheckout] = useState({
    pay_mode_id: undefined,
    delivery_mode_id: undefined,
    shipping_address_id: undefined,
    coupon_id: undefined,
    comment: undefined
  });

  return (
    <CheckoutContext.Provider value={[checkout, setCheckout]} >
      {props.children}
    </CheckoutContext.Provider>
  );
}