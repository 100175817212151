import React, { useState, createContext } from 'react';
export const FormContext = createContext();

export const FormProvider = props => {
  const [form, setForm] = useState({
    copyData: {
      copyId: undefined,
      data: undefined
    }
  });

  return (
    <FormContext.Provider value={[form, setForm]} >
      {props.children}
    </FormContext.Provider>
  );
}