import React, { useState, createContext } from "react";
export const ShopContext = createContext();

export const ShopProvider = (props) => {
    const [shop, setShop] = useState({
        mainId: 791,
        filterId: 795,
        productId: 791,
        user: undefined,
        addresses: undefined,
        filters: [
        {
            //0
            id: 0,
            realID: 792,
            name: "Épitő porok",
            items: undefined,
            state: false,
        },
        {
            //1
            id: 1,
            realID: 793,
            name: "Ecsetes folyadékok",
            items: undefined,
            state: false,
        },
        {
            //2
            id: 2,
            realID: 794,
            name: "Kezdő készlet",
            items: undefined,
            state: false,
        },
        {
            //3
            id: 3,
            realID: 795,
            name: "Szines porok",
            items: [
            {
                realID: 796,
                name: "COLOR",
                state: false,
            },
            {
                realID: 800,
                name: "CSILLÁMOS",
                state: false,
            },
            {
                realID: 798,
                name: "CHAMELEON",
                state: false,
            },
            {
                realID: 806,
                name: "NUDE",
                state: false,
            },
            {
                realID: 801,
                name: "GLOW IN THE DARK",
                state: false,
            },
            {
                realID: 795,
                name: "ÖSSZES",
                state: false,
            }, 
            ],
            state: false,
        },
        {
            //4
            id: 4,
            realID: 802,
            name: "Kiegészítők",
            items: undefined,
            state: false,
        },
        ],
        filtersIds: [792, 793, 794, 795, 796, 800, 798, 806, 801, 802],
        favActiv: false,
        favorites: [],
        products: undefined,
        activProducts: undefined,
        searchProducts: undefined,
        cart: [],
        freeShippingLimit: 5000,
        shipping: 890,
        coupons: [],
        selectedCoupon: undefined,
        delivery_modes: [],
        selectedDelivery: 1,
        pay_modes: [],
        checkoutData: undefined,
        shippingAddresses: undefined,
    });

    return (
        <ShopContext.Provider value={[shop, setShop]}>
        {props.children}
        </ShopContext.Provider>
    );
};