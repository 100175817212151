import React, { useState, createContext } from 'react';
export const DisplayContext = createContext();

export const DisplayProvider = props => {
  const [display, setDisplay] = useState({
    calc: false,
    from: 0,
    items: undefined,
    maxItem: 12,
    page: 1,
    maxPage: undefined,
    refresh: false
  });

  return (
    <DisplayContext.Provider value={[display, setDisplay]} >
      {props.children}
    </DisplayContext.Provider>
  );
}
